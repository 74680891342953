import HistoryCard from "../Components/HistoryCard";

const History = () => {
  return (
    <div>
      <HistoryCard />
    </div>
  );
};

export default History;
