import { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Container, Button } from "@mantine/core";
import ScrollableFile from "./ScrollableFile";
import { useNavigate } from "react-router-dom";


const ResultPage = () => {
  const { id } = useParams(); // Get the ID from the URL
  const navigate = useNavigate();
  const [documentation, setDocumentation] = useState(null);
  const [title, setTitle] = useState<string>("");

  useEffect(() => {
    const fetchRecord = async () => {
      try {
        const response = await axios.get(
          `https://ai-docs-eb77a9aba990.herokuapp.com/saved-query/?id=${id}`,
        );
        setDocumentation(JSON.parse(response.data.response));
        setTitle(response.data.name);
      } catch (err: any) {
        console.log(err);
      }
    };

    fetchRecord();
  }, [id]);

  return (
    <Container style={{ display: "flex", justifyContent: "Center" }}>
      <div
        style={{
          flexDirection: "column",
          display: "flex",
          maxWidth: "1200px",
          width: "80vw",
        }}
      >
        {" "}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            maxWidth: "1200px",
            width: "80vw",
          }}
        >
          <h1>{title}</h1>
        </div>
        <div className={"markdown-body"}>
          <ScrollableFile content={documentation} />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            maxWidth: "1200px",
            width: "80vw",
            marginTop: "2%",
          }}
        >
          <Button
            color="red"
            variant="filled"
            radius="md"
            w={"10%"}
            size="lg"
            onClick={() => {
              // Handle delete action here
              console.log("Delete button clicked");
            }}
          >
            Delete
          </Button>
          <Button
            color="grey"
            variant="filled"
            radius="md"
            w={"10%"}
            ml={10}
            size="lg"
            onClick={() => {
              // Handle delete action here
              navigate("/History");
              console.log("Delete button clicked");
            }}
          >
            History
          </Button>
        </div>
      </div>
    </Container>
  );
};

export default ResultPage;
