import {
  Group,
  Button,
  UnstyledButton,
  Text,
  ThemeIcon,
  Divider,
  Center,
  Box,
  Burger,
  Drawer,
  Collapse,
  ScrollArea,
  rem,
  useMantineTheme,
} from "@mantine/core";
import { useAuth0 } from "@auth0/auth0-react";
import { useMediaQuery, useDisclosure } from "@mantine/hooks";
import "@mantine/core/styles.css";
import {
  IconNotification,
  IconCode,
  IconBook,
  IconChartPie3,
  IconFingerprint,
  IconCoin,
  IconChevronDown,
} from "@tabler/icons-react";
import classes from "../Css/HeaderMegaMenu.module.css";
import { useNavigate } from "react-router-dom";

const mockdata = [
  {
    icon: IconCode,
    title: "Industry-Relevant Skills",
    description:
      "Learn the latest and most in-demand skills that are essential in the tech industry today.",
  },
  {
    icon: IconCoin,
    title: "Accessible Learning",
    description:
      "Our program is designed to be accessible, offering various learning materials for all skill levels.",
  },
  {
    icon: IconBook,
    title: "Comprehensive Curriculum",
    description:
      "Our curriculum covers a wide range of topics, ensuring a thorough understanding of tech concepts.",
  },
  {
    icon: IconFingerprint,
    title: "Personalized Pathways",
    description:
      "We offer personalized learning pathways to suit your individual career goals and interests.",
  },
  {
    icon: IconChartPie3,
    title: "Career Progression Tracking",
    description:
      "Track your progress and development with our career progression tools and resources.",
  },
  {
    icon: IconNotification,
    title: "Ongoing Support",
    description:
      "Receive continuous support and guidance from mentors throughout your learning journey.",
  },
];

export function HeaderMegaMenu() {
  const { loginWithRedirect, logout, isAuthenticated } = useAuth0();
  const { user } = useAuth0();

  const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] =
    useDisclosure(false);
  const navigate = useNavigate();
  const [linksOpened, { toggle: toggleLinks }] = useDisclosure(false);
  const theme = useMantineTheme();
  const isLargeScreen = useMediaQuery("(min-width: 1400px)");

  const links = mockdata.map((item) => (
    <UnstyledButton className={classes.subLink} key={item.title}>
      <Group wrap="nowrap" align="flex-start">
        <ThemeIcon size={34} variant="default" radius="md">
          <item.icon
            style={{ width: rem(22), height: rem(22) }}
            color={theme.colors.blue[6]}
          />
        </ThemeIcon>
        <div>
          <Text size="sm" fw={500}>
            {item.title}
          </Text>
          <Text size="xs" c="dimmed">
            {item.description}
          </Text>
        </div>
      </Group>
    </UnstyledButton>
  ));

  return (
    <Box mt={12} miw={isLargeScreen ? 1400 : "90%"} pb={0}>
      <header className={classes.header}>
        <Group justify="space-between" h="100%">
          <div style={{ display: "flex", alignItems: "center" }}>
            <Text c="#002D62" size="lg" ml={10} fw={700}>
              AI Document Generation
            </Text>
          </div>

          <Group h="100%" gap={0} visibleFrom="sm">
            <div
              onClick={() => navigate("/")}
              className={classes.link}
              style={{ cursor: "pointer" }}
            >
              Home
            </div>
            {user && <div
              onClick={() => navigate("/History")}
              className={classes.link}
              style={{ cursor: "pointer" }}
            >
              History
            </div>}
            {user && <div
              onClick={() => navigate("/Generate")}
              className={classes.link}
              style={{ cursor: "pointer" }}
            >
              Generate
            </div>}
            <div
              onClick={() => navigate("/Organization")}
              className={classes.link}
              style={{ cursor: "pointer" }}
            >
              Organization
            </div>
          </Group>
          {isAuthenticated ? (
            <Group visibleFrom="sm">
              <Button
                onClick={() =>
                  logout({ logoutParams: { returnTo: window.location.origin } })
                }
                variant="default"
              >
                Log Off
              </Button>
            </Group>
          ) : (
            <Group visibleFrom="sm">
              <Button onClick={() => loginWithRedirect()} variant="default">
                Log in
              </Button>
            </Group>
          )}

          <Burger
            opened={drawerOpened}
            onClick={toggleDrawer}
            hiddenFrom="sm"
          />
        </Group>
      </header>

      <Drawer
        opened={drawerOpened}
        onClose={closeDrawer}
        size="100%"
        padding="md"
        title="Navigation"
        hiddenFrom="sm"
        zIndex={1000000}
      >
        <ScrollArea h={`calc(100vh - ${rem(80)})`} mx="-md">
          <Divider my="sm" />
          <div
            onClick={() => navigate("/")}
            className={classes.link}
            style={{ cursor: "pointer", marginBottom: "10px" }}
          >
            Home
          </div>
          <UnstyledButton
            className={classes.link}
            onClick={toggleLinks}
            mb={10}
          >
            <Center inline>
              <Box component="span" mr={5}>
                Programs
              </Box>
              <IconChevronDown
                style={{ width: rem(16), height: rem(16) }}
                color={theme.colors.blue[6]}
              />
            </Center>
          </UnstyledButton>
          <Collapse in={linksOpened}>{links}</Collapse>
          <a href="/" className={classes.link} style={{ marginBottom: "10px" }}>
            Learn
          </a>
          <a href="/" className={classes.link} style={{ marginBottom: "10px" }}>
            Academy
          </a>

          <Divider my="sm" />

          <Group justify="center" grow pb="xl" px="md">
            <Button variant="default">Log in</Button>
            <Button>Sign up</Button>
          </Group>
        </ScrollArea>
      </Drawer>
    </Box>
  );
}
