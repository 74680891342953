import React from "react";

// You can import your icons like this if they are SVGs or image files
// import jsIcon from './path/to/javascriptIcon.svg';
// import pythonIcon from './path/to/pythonIcon.svg';
// For simplicity, let's assume these are the paths to your icons:
import jsIcon from "../Images/Javascript_img.png";
import cIcon from "../Images/C_plus.png";
import pythonIcon from "../Images/python_svg.png";
import cSharpIcon from "../Images/C_sharp.png";
import defaultIcon from "../Images/document_icon.png";
import javaIcon from "../Images/java_img.png";
import typeIcon from "../Images/type_icon.png";
import { rem } from "@mantine/core";

const icons: { [key: string]: string } = {
  javascript: jsIcon,
  python: pythonIcon,
  java: javaIcon,
  csharp: cSharpIcon,
  cpp: cIcon,
  typescript: typeIcon,
  default: defaultIcon, // Default icon
};

const LanguageIcon = ({ language }: { language: string }) => {
  // Select the icon based on the provided language
  const iconPath = icons[language] || icons.default;

  return (
    <img
      style={{ width: rem(45), height: rem(45) }}
      src={iconPath}
      alt={language + " icon"}
    />
  );
};

export default LanguageIcon;
