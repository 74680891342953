import {
  Badge,
  Group,
  Title,
  Text,
  Card,
  SimpleGrid,
  Container,
  rem,
  useMantineTheme,
} from "@mantine/core";
import { IconUser, IconUsers, IconAward } from "@tabler/icons-react"; // Updated icons
import classes from "../Css/FeaturesCards.module.css";

const mockdata = [
  {
    title: "Personalized Coaching",
    description:
      "Receive individual mentorship from industry experts, tailored to your specific career goals in the tech industry.",
    icon: IconUser, // Changed to IconUser
  },
  {
    title: "Networking Opportunities",
    description:
      "Connect with professionals and peers in the tech community to build valuable relationships and expand your professional network.",
    icon: IconUsers,
  },
  {
    title: "Career Support",
    description:
      "From resume building to interview preparation, our program offers comprehensive support to help you navigate the tech job market.",
    icon: IconAward, // Changed to IconAward
  },
];

export function FeaturesCards() {
  const theme = useMantineTheme();
  const features = mockdata.map((feature) => (
    <Card
      key={feature.title}
      shadow="md"
      radius="md"
      className={classes.card}
      padding="xl"
    >
      <feature.icon
        style={{ width: rem(50), height: rem(50) }}
        stroke={2}
        color={theme.colors.blue[6]}
      />
      <Text fz="lg" fw={500} className={classes.cardTitle} mt="md">
        {feature.title}
      </Text>
      <Text fz="sm" c="dimmed" mt="sm">
        {feature.description}
      </Text>
    </Card>
  ));

  return (
    <Container size="lg" py="xl" pt={150}>
      <Group justify="center">
        <Badge variant="filled" size="lg" color="#002D62">
          AI Documents
        </Badge>
      </Group>

      <Title order={2} className={classes.title} ta="center" mt="sm">
        Elevate Your Tech Career
      </Title>

      <Text c="dimmed" className={classes.description} ta="center" mt="md">
        Join TechTorch Careers to gain the skills, knowledge, and connections
        needed for a thriving career in technology.
      </Text>

      <SimpleGrid cols={{ base: 1, md: 3 }} spacing="xl" mt={50}>
        {features}
      </SimpleGrid>
    </Container>
  );
}
