import { Title, Text, Button, Container } from "@mantine/core";
import { Dots } from "./Dots";
import classes from "../Css/HeroText.module.css";
import { useNavigate } from "react-router-dom";

export function HeroText() {
  const navigate = useNavigate();
  return (
    <Container className={classes.wrapper} size={1400} mt={30}>
      <Dots className={classes.dots} style={{ left: 0, top: 0 }} />
      <Dots className={classes.dots} style={{ left: 60, top: 0 }} />
      <Dots className={classes.dots} style={{ left: 0, top: 140 }} />
      <Dots className={classes.dots} style={{ right: 0, top: 60 }} />

      <div className={classes.inner}>
        <Title className={classes.title} pt={55}>
          Welcome to{" "}
          <Text component="span" className={classes.highlight} inherit>
            AI Document Generation
          </Text>
        </Title>

        <Container p={0} size={600} pt={20}>
          <Text size="lg" className={classes.description}>
            Embark on a transformative journey in the tech industry with our
            cutting-edge coaching program. Whether starting out or scaling new
            heights, TechTorch Careers is your gateway to success.
          </Text>
        </Container>

        <div className={classes.controls}>
          <Button
            className={classes.control}
            size="lg"
            color="#002D62"
            onClick={() => navigate("/Organization")}
          >
            Create Organization  
          </Button>
        </div>
      </div>
    </Container>
  );
}
