import React from 'react';
import { useForm } from '@mantine/form';
import { TextInput, Button, Box, Container } from '@mantine/core';

interface OrganizationFormValues {
  name: string;
  description: string;
}

const Organization: React.FC = () => {
  const form = useForm<OrganizationFormValues>({
    initialValues: {
      name: '',
      description: '',
    },

    validate: {
      name: (value) => (value.length < 2 ? 'Name must have at least 2 characters' : null),
    },
  });

  const handleSubmit = (values: OrganizationFormValues) => {
    console.log('Organization Created:', values);
    // Handle form submission here (e.g., send data to an API)
  };

  return (
    <Container size="md" style={{ height: '25vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Box style={{ width: '100%', maxWidth: 600 }}>
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <TextInput
            label="Organization Name"
            placeholder="Enter organization name"
            {...form.getInputProps('name')}
          />

          <TextInput
            label="Description"
            placeholder="Enter description"
            {...form.getInputProps('description')}
          />

          <Button type="submit" mt="md">
            Create Organization
          </Button>
        </form>
      </Box>
    </Container>
  );
};

export default Organization;