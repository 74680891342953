import { ScrollArea, Card, Text } from "@mantine/core";
import ReactMarkdown from "react-markdown";

const ScrollableFile = ({ content }: any) => {
  return (
    <Card
      shadow="sm"
      padding="lg"
      style={{
        paddingRight: "0px",
        maxHeight: "100%",
        overflow: "hidden",
        border: "1px solid #e0e0e0",
        borderRadius: "8px",
        position: "relative",
      }}
    >
      <Card.Section
        style={{
          height: "100%",
          backgroundColor: "#f0f0f0",
          borderBottom: "1px solid #e0e0e0",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontWeight: "bold",
        }}
      >
        Your Documentation
      </Card.Section>
      <ScrollArea style={{ height: "75vh" }} offsetScrollbars>
        {" "}
        <Text>
          <ReactMarkdown>{content}</ReactMarkdown>
        </Text>
      </ScrollArea>
    </Card>
  );
};

export default ScrollableFile;
