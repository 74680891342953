import ReactDOM from "react-dom/client";
import "./Css/index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "@mantine/core/styles.css";
import { MantineProvider, createTheme } from "@mantine/core";
import { Auth0Provider } from "@auth0/auth0-react";

const theme = createTheme({
  /** Put your mantine theme override here */
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <Auth0Provider
    domain="ai-docs.us.auth0.com"
    clientId="yLv0Fj5jqXUdTLgnuqRJjeUE48tWv6lR"
    authorizationParams={{
      redirect_uri: window.location.origin,
    }}
  >
    <MantineProvider theme={theme}>
      <App />
    </MantineProvider>
  </Auth0Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
