import {
  Paper,
  Text,
  Container,
  Pagination,
  Select,
  Input,
} from "@mantine/core";
import { useCallback, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import classes from "../Css/CardGradient.module.css";
import LanguageIcon from "./LanguageIcon";
import { useNavigate } from "react-router-dom";

export function HistoryCard() {
  const { user } = useAuth0();
  const navigate = useNavigate();
  const [documents, setDocuments] = useState<any>([]);
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<string>("12");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [total, setTotal] = useState<number>(0);

  const fetchData = useCallback(
    async (query?: string) => {
      // Fetch data from external API
      if (user) {
        const response = await fetch(
          `https://ai-docs-eb77a9aba990.herokuapp.com/saved-query/get-by-user?auth0Id=${user.sub}&page=${page}&limit=${parseInt(limit)}&search=${query}`
        );
        const data = await response.json();
        setDocuments(data["queries"]);
        setTotal(Math.ceil(data["total"] / Number(limit)));
      }
    },
    [user, page, limit]
  );

  useEffect(() => {
    fetchData(searchTerm);
  }, [user, page, limit, fetchData, searchTerm]);

  const handleInputChange = (e: any) => {
    setPage(1);
    setSearchTerm(e.target.value);
    fetchData(e.target.value);
  };

  const handleSelectChange = (value: string | null) => {
    if (value !== null) {
      setLimit(value);
      setSearchTerm("");
    }
  };

  // See groceries data above
  const items = documents?.map((item: any) => {
    return (
      <Paper
        style={{ paddingLeft: "32px" }}
        onClick={() => navigate(`/result/${item.id}`)}
        key={item.id}
        withBorder
        radius="md"
        className={classes.card}
      >
        <LanguageIcon language={JSON.parse(item.query).language} />
        <Text size="xl" fw={500} mt="md">
          {item.name}
        </Text>
        <Text size="sm" mt="sm" c="dimmed">
          {item.summary}
        </Text>
      </Paper>
    );
  });

  return (
    <Container
      style={{ display: "flex", justifyContent: "Center" }}
      className={classes.wrapper}
      size={1400}
      mt={30}
    >
      {" "}
      <div
        style={{
          maxWidth: "1200px",
          width: "80vw",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          {" "}
          <h1>History</h1>{" "}
          <Input
            value={searchTerm}
            onChange={handleInputChange}
            placeholder="Type to search..."
          />
        </div>
        <div className={classes.grid_container}>{items}</div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {" "}
          <Pagination total={total} value={page} onChange={setPage} mt="sm" />
          <Select
            value={limit.toString()} // Convert limit to a string
            onChange={handleSelectChange}
            data={[
              { value: "12", label: "12 Options" },
              { value: "24", label: "24 Options" },
              { value: "36", label: "36 Options" },
            ]}
            style={{ width: 200, marginLeft: 13, marginTop: 10 }}
          />
        </div>
      </div>
    </Container>
  );
}

export default HistoryCard;
