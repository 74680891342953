import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Views/Home";
import PrivateRoutes from "./PrivateRoute";
import { HeaderMegaMenu } from "./Components/HeaderMegaMenu";
import { Divider } from "@mantine/core";
import History from "./Views/History";
import GenerateDocs from "./Components/GenerateDocs";
import ResultPage from "./Components/ResultPage";
import Organization from "./Components/Organization";

function App() {
  return (
    <Router>
      <div
        style={{ display: "flex", justifyContent: "center", width: "99vw" }}
      >
        <HeaderMegaMenu />
      </div>
      <Divider my="sm" />
      <Routes>
        <Route element={<PrivateRoutes />}>
          <Route element={<History />} path="/History" />
          <Route element={<GenerateDocs />} path="/Generate" />
          <Route element={<ResultPage />} path="/result/:id" />
        </Route>
        <Route element={<Home />} path="/" />
        <Route element={<Organization />} path="/organization" />
      </Routes>
    </Router>
  );
}

export default App;
