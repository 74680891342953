import { HeroText } from "../Components/HeroText";
import { FeaturesCards } from "../Components/FeaturesCards";

function Home() {
  return (
    <div style={{ paddingTop: "20px" }}>
      <HeroText />
      <FeaturesCards />
    </div>
  );
}

export default Home;
