import React, { useEffect, useState } from "react";
import classes from "../Css/HeroText.module.css";
import {
  Button,
  Container,
  Checkbox,
  Select,
  Textarea,
  Input,
} from "@mantine/core";
import spinner from "../Css/Spinner.module.css";
import styles from "../Css/Editor.module.css";
import { Dots } from "./Dots";
import { Editor } from "@monaco-editor/react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { useAuth0 } from "@auth0/auth0-react";

const GenerateDocs: React.FC = () => {
  const [codeSnippet, setCodeSnippet] = useState<string>("");
  const [language, setLanguage] = useState<string>("javascript");
  const [purpose, setPurpose] = useState<string>("");
  const [depends, setDepends] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [userId, setUserId] = useState<string>("");
  const { user } = useAuth0();

  const navigate = useNavigate();

  useEffect(() => {
    if (user?.sub) {
      setUserId(user.sub);
    }
  });

  const handleGenerateDocumentation = async () => {
    const toastId = toast.loading("Loading...");
    setLoading(true);
    try {
      const res = await axios.post(
        "https://ai-docs-eb77a9aba990.herokuapp.com/gpt-interface/",
        {
          codeBody: codeSnippet,
          language: language,
          dependencies: depends,
          purpose: purpose,
          title: title,
          userId: userId,
        },
      );
      setLoading(false);
      toast.success("Request successful!", { id: toastId });
      navigate(`/result/${res.data.queryId}`);
    } catch (error) {
      toast.error("Error fetching data!", { id: toastId });
      console.error("Error generating documentation:", error);
    }
  };

  return (
    <Container
      style={{ display: "flex", justifyContent: "Center" }}
      className={classes.wrapper}
      size={1400}
      mt={30}
    >
      <Dots className={classes.dots} style={{ left: 0, top: 0 }} />
      <Dots className={classes.dots} style={{ left: 60, top: 0 }} />
      <Dots className={classes.dots} style={{ left: 0, top: 140 }} />
      <Dots className={classes.dots} style={{ right: 0, top: 60 }} />

      <div className={classes.inner}>
        <div
          style={{
            flexDirection: "column",
            display: "flex",
            maxWidth: "1200px",
            width: "80vw",
          }}
        >
          <h2>Auto-Generate Documentation</h2>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Input.Wrapper label="Title" w={"40%"} mb={"1%"}>
              <Input
                onChange={(e) => setTitle(e.target.value ?? "")}
                placeholder="Name your documentation"
              />
            </Input.Wrapper>
            <Select
              style={{ width: "40%", marginBottom: "-10px" }}
              label="Your coding language"
              placeholder="Pick value"
              data={[
                "plaintext",
                "abap",
                "apex",
                "azcli",
                "bat",
                "bicep",
                "cameligo",
                "clojure",
                "coffeescript",
                "c",
                "cpp",
                "csharp",
                "csp",
                "css",
                "cypher",
                "dart",
                "go",
                "graphql",
                "handlebars",
                "hcl",
                "html",
                "ini",
                "java",
                "javascript",
                "julia",
                "kotlin",
                "less",
                "lexon",
                "lua",
                "liquid",
                "m3",
                "markdown",
                "mdx",
                "mips",
                "msdax",
                "mysql",
                "objective-c",
                "pascal",
                "pascaligo",
                "perl",
                "pgsql",
                "php",
                "pla",
                "postiats",
                "powerquery",
                "powershell",
                "proto",
                "pug",
                "python",
                "qsharp",
                "r",
                "razor",
                "redis",
                "redshift",
                "restructuredtext",
                "ruby",
                "rust",
                "sb",
                "scala",
                "scheme",
                "scss",
                "shell",
                "sol",
                "aes",
                "sparql",
                "sql",
                "st",
                "swift",
                "systemverilog",
                "verilog",
                "tcl",
                "twig",
                "typescript",
                "typespec",
                "vb",
                "wgsl",
                "xml",
                "yaml",
                "json",
              ]}
              searchable
              allowDeselect={false}
              onChange={(e) => setLanguage(e ?? "")}
            />
          </div>
          <div>
            <h4>Code Snippet</h4>
            <Editor
              className={styles.editorcontainer}
              height="600px" // Set the height of the editor
              language={language} // Set the language of the editor
              defaultLanguage="typescript" // Set the default language
              defaultValue="// Input code here"
              theme="vs-dark" // Set the theme to dark
              options={{
                fontSize: 16, // Set the font size
                automaticLayout: true, // Automatically adjust the layout when resizing
                minimap: { enabled: false }, // Disable the minimap for simplicity
                scrollBeyondLastLine: false, // Disable scrolling beyond the last line
                lineNumbers: "on", // Enable line numbers
                tabSize: 2, // Set tab size
              }}
              onChange={(e) => setCodeSnippet(e ?? "")}
            />
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Textarea
                label="Purpose of the code"
                placeholder="Purpose"
                w={"45%"}
                autosize
                minRows={10}
                onChange={(e) => setPurpose(e.target.value ?? "")}
              />
              <Textarea
                label="Dependenices of the code"
                placeholder="Dependenices"
                w={"45%"}
                autosize
                minRows={10}
                onChange={(e) => setDepends(e.target.value ?? "")}
              />
            </div>
            <Checkbox label="I accepts terms & conditions" mt="sm" />
          </div>
          <br />
          <Button
            onClick={handleGenerateDocumentation}
            color="orange"
            fullWidth
            mb={20}
            disabled={loading}
          >
            {loading ? (
              <div style={{ padding: "3px" }} className={spinner.spinner}></div>
            ) : (
              "Generate Documentation"
            )}
          </Button>
          <Toaster />
        </div>
      </div>
    </Container>
  );
};

export default GenerateDocs;
